import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { api } from "../../helpers/api";
import { Form, Input, Modal, notification } from "antd";
import { RouteConstants } from "../../helpers/constants";
import { NetworkProvider, Option } from "../../types";
import { getCookie } from "../../helpers/utils";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import { Spinner } from "@material-tailwind/react";
import PaymentProcessing from "../components/PaymentProcessing";
import StillProcessing from "../components/StillProcessing";
import { db } from "../../types/db";
import { PORTAL_URL } from "../../helpers/constants";
import { XMarkIcon } from "@heroicons/react/24/solid";
import CustomButton from "../../components/CustomBtn";

type Props = {};

const PaymentDetail = (props: Props) => {
  const navigate = useNavigate();

  const ticket: any = localStorage.getItem("ticket");
  const ticketDetails = JSON.parse(ticket);

  const [form] = Form.useForm();
  const [fetchingProviders, setFetchingProviders] = useState(false);
  const [networks, setNetworks] = useState<Option[]>([]);
  const [selectedMethod, setSelectedMethod] = useState<Option | null>(null);
  const [pay_phone, setPaymentPhone] = useState<string | undefined>();

  //processed
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [stilProcessing, setStillProcessing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [countdown, setCountdown] = useState(120); // Initial countdown value
  const [isCounting, setIsCounting] = useState(false);

  const token = getCookie("oya_token");

  const getNetworkProviders = async () => {
    try {
      setFetchingProviders(true);
      const { data } = await api.get(RouteConstants.NETWORK_PROVIDER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNetworks(
        data?.payload?.items?.map((item: NetworkProvider, index: number) => ({
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (e) {
      console.log(e);
    } finally {
      setFetchingProviders(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const tickets = ticketDetails?.map((item: any) => item?.id);
      const res = await api.post(
        RouteConstants.PAYMENTS,
        {
          account_provider: selectedMethod?.value,
          account_name: form.getFieldValue("account_name"),
          account_number: pay_phone,
          method: selectedMethod?.value,
          services: tickets,
          type: "DEBIT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const socket = new WebSocket(
        `${res.data?.payload?.realtime?.endpoint}?token=${res.data?.payload?.realtime?.token}`
      );
      setIsCounting(true);
      setProcessing(true);
      setSubmitting(false);

      // Connection opened
      socket.addEventListener("open", (event) => {
        console.log("socket connection open");
      });

      // Listen for messages
      socket.addEventListener("message", async (event) => {
        const data = JSON.parse(event.data);

        if (data?.status?.code === "SUCCESS") {
          setSuccess(true);
          setProcessing(false);
          setIsCounting(false);

          localStorage.removeItem("ticket");
          localStorage.removeItem("details");
          localStorage.removeItem("trip_summary");

          await db.seats.clear();

          socket.close();
        }

        if (data?.status?.code === "FAILED") {
          setFailed(true);
          setSuccess(false);
          setProcessing(false);
          setIsCounting(false);

          socket.close();
        }

        //console.log('Message from server ', event.data);
      });
    } catch (e: any) {
      notification.error({
        message: e?.response?.data?.message || e?.message,
        duration: 15,
      });
      setSubmitting(false);
      //handleClosed();
      //navigate(-1);
    }
  };

  useEffect(() => {
    let timer: any;
    if (isCounting && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setStillProcessing(true);
      setProcessing(false);
      setSuccess(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isCounting, countdown]);

  useEffect(() => {
    getNetworkProviders();

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta
          name='apple-mobile-web-app-status-bar-style'
          content={"#B40303"}
        />
        <meta name='theme-color' content={"#fff"} />
      </Helmet>

      <div className='bg-[#fff] relative min-h-screen overflow-hidden'>
        {processing ? (
          <PaymentProcessing
            phone={pay_phone}
            handleClose={() => navigate("/")}
          />
        ) : stilProcessing ? (
          <StillProcessing handleDismiss={() => navigate("/")} />
        ) : (
          <>
            <div className='top-0 fixed z-[100] w-full'>
              <div className='relative w-full inline-flex'>
                <div className='bg-white h-[44px] w-full pt-5 pb-3 px-[15px] '>
                  <div className='flex items-center'>
                    <div className='flex-[0.3]'>
                      <button onClick={() => navigate(-1)}>
                        <ChevronLeftIcon className='w-7 h-7 ' />
                      </button>
                    </div>
                    <div className='flex-[0.7]'>
                      <div className='header-text-3'>Payment method</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=' mt-[7rem]'>
              <div className=' min-h-screen overflow-y-auto'>
                <div className='px-[15px]'>
                  <Form form={form} layout='vertical'>
                    <Form.Item
                      colon={false}
                      label='Payment method'
                      name='account_provider'
                    >
                      <Select
                        placeholder='Select payment method'
                        className='h-[44px]'
                        onChange={(e) => {
                          // let findOne = networks.find(
                          //   (value) => value?.code === e
                          // );
                          setSelectedMethod(e);
                        }}
                        options={networks}
                        isSearchable
                        isClearable
                        isLoading={fetchingProviders}
                      />
                    </Form.Item>

                    {selectedMethod && (
                      <>
                        <Form.Item
                          colon={false}
                          label='Your payment number'
                          name='account_number'
                        >
                          <PhoneInput
                            value={pay_phone}
                            onChange={(e) => {
                              let formatted = e?.replace(/\s+/g, "");
                              setPaymentPhone(formatted);
                            }}
                            placeholder='0240000000'
                            className='phone bg-white w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none'
                            defaultCountry='GH'
                          />
                        </Form.Item>

                        <Form.Item
                          colon={false}
                          label='Your payment name'
                          name='account_name'
                        >
                          <Input
                            size='large'
                            placeholder='Enter your payment name'
                          />
                        </Form.Item>

                        <div className='flex w-full justify-between items-center mt-5'>
                          <button
                            onClick={() => handleSubmit()}
                            disabled={submitting}
                            className='bg-oya-red-100 rounded-lg w-full h-[42px] flex justify-center items-center text-white'
                          >
                            {submitting ? (
                              <Spinner />
                            ) : (
                              <div className='text-[16px]'>Buy ticket</div>
                            )}
                          </button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </>
        )}

        <Modal footer={null} open={success} onCancel={() => setSuccess(false)}>
          <div className='success-popup'>
            <div className='flex flex-col justify-center mt-4 items-center gap-3'>
              {/* <img src={successImg} alt='processing' /> */}
              <div className='border-[4px] rounded-full w-[63px] h-[63px] border-seat-green flex items-center justify-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25'
                  height='18'
                  viewBox='0 0 25 18'
                  fill='none'
                >
                  <path
                    d='M1.5 9.29247L8.79247 16.5849L23.34 2'
                    stroke='#2ABB7F'
                    stroke-width='2.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <div className='text-center text-[24px] font-semibold text-seat-green py-2'>
                Booking successful!
              </div>
            </div>
            <div className='text-[16px] text-center'>
              Your trip has been booked successfully.
            </div>
            <div className='text-oya-red-50 text-center text-[16px]'>
              <button
                onClick={() => {
                  window.open(`${PORTAL_URL}/trips`, "_blank");
                  navigate("/");
                }}
                data-testid='viewTicket'
              >
                View ticket
              </button>
            </div>
          </div>
        </Modal>

        <Modal footer={null} open={failed} onCancel={() => setFailed(false)}>
          {failed && (
            <div>
              <div className='flex items-center gap-3'>
                <XMarkIcon className='w-12 h-12 text-red-500' />
                <div className='text-center text-[24px] font-semibold text-red-500 py-1'>
                  Payment Failed
                </div>
              </div>
              <div className='flex items-center gap-1'>
                <div className='text-center py-3 text-lg'>
                  We could not process your payment. Try again later.
                </div>
              </div>
              <CustomButton
                data-testid='closeGuestPayment'
                onClick={() => {
                  //setStillProcessing(false);

                  setFailed(false);
                  navigate(-1);
                }}
              >
                Close
              </CustomButton>
            </div>
          )}
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default PaymentDetail;
