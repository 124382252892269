import React, { useState, useEffect } from 'react';
import PageLayout from '../components/utils/PageLayout';
import {
  Divider,
  Form,
  Spin,
  notification,
  Input,
  Modal,
  InputNumber,
  Space,
  Radio,
} from 'antd';
import { useMediaQuery } from '@mui/material';
import { Skeleton } from 'antd';
import PhoneInput from 'react-phone-number-input';
import { ArrowBackIos } from '@mui/icons-material';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { PORTAL_URL, RouteConstants } from '../helpers/constants';
import Loader from '../components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { GuestSchedule, Seat, MidRoute, Option } from '../types';
import { api } from '../helpers/api';
import Select from 'react-select';
import moment from 'moment';
import CustomButton from '../components/CustomBtn';
import loadingImg from '../assets/img/loading.gif';
import { getCookie, validateUserName } from '../helpers/utils';
import { networks } from '../helpers/utils';
import Signin from '../components/Auth/Signin';
import SignUp from '../components/Auth/Signup';
import ReactGA from 'react-ga';
import verifyImg from '../assets/img/verify.gif';

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';

type CustomSeatType = {
  id: number;
  number: number;
  status: number;
  selected: boolean;
};

const GuestBooking = () => {
  const required = {
    0: ['first_name', 'last_name'],
  };
  const [form] = Form.useForm();
  const [schedulDetails, setScheduleDetails] = useState<
    GuestSchedule | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [stilProcessing, setStillProcessing] = useState(false);
  const [failed, setFailed] = useState(false);

  const [pickups, setPickups] = useState<Option[]>([]);
  const [selectedPickup, setSelectedPickup] = useState(0);
  const [selectedMidRoute, setSelectedMidRoute] = useState<any>();

  const [selectedProvider, setSelectedProvider] = useState<any>();
  //const [selectedSeat, setSelectedSeat] = useState<any>();
  const [countdown, setCountdown] = useState(60); // Initial countdown value
  const [isCounting, setIsCounting] = useState(false);
  const [phone, setPhone] = useState<string | undefined>();
  const [pay_phone, setPaymentPhone] = useState<string | undefined>();
  const [ice_phone, setICEPhone] = useState<string | undefined>();
  const [ticketLink, setTicketLink] = useState<any>();
  const [accordionOpen, setAccordionOpen] = useState(1);

  const [seatsLoading, setSeatsLoading] = useState(false);

  const [openLogIn, setOpenLogIn] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [busSeats, setBusSeats] = useState<CustomSeatType[]>([]);

  const [otp_required, setOTPRequired] = useState(false);
  const [submittingOTP, setSubmittingOTP] = useState(false);
  const [paymentId, setPaymentId] = useState<any>();

  const [socketUrl, setSocketUrl] = useState<string | null>(null);
  const [socketToken, setSocketToken] = useState<string | null>(null);
  const [otp, setOtp] = useState('');

  const [seatArray, setSeatArray] = useState<any[]>([]);
  const [seatObjArray, setSeatObjArray] = useState<Seat[]>([]);

  const isLarge = useMediaQuery('(min-width:1024px)');
  const token = getCookie('oya_token');

  const navigate = useNavigate();
  const params = useParams();

  const submitOtp = async (paymentId: any) => {
    if (otp) {
      setSubmittingOTP(true);
      try {
        const { data } = await api.post(
          `${RouteConstants.TRANSACTIONS}/${paymentId}/authorize`,
          {
            credential: otp,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        notification.success({
          message: 'OTP Verified',
          description: data.message,
          duration: 15,
        });
        notification.info({
          message: 'Payment Initiated. Waiting for approval...',
          duration: 15,
        });

        const socket = new WebSocket(`${socketUrl}?token=${socketToken}`);
        setIsBusy(true);
        setProcessing(true);
        setSubmitting(false);
        setIsCounting(true);

        // Connection opened
        socket.addEventListener('open', (event) => {
          console.log('socket connection open');
        });

        // Listen for messages
        socket.addEventListener('message', (event) => {
          const data = JSON.parse(event.data);

          if (data?.status?.code === 'SUCCESS') {
            setSuccess(true);
            setProcessing(false);
            setIsCounting(false);
            socket.close();
          }

          if (data?.status?.code === 'FAILED') {
            setFailed(true);
            setSuccess(false);
            setProcessing(false);
            setIsCounting(false);

            socket.close();
          }

          //console.log('Message from server ', event.data);
        });
      } catch (e: any) {
        notification.error({
          message: e?.response?.data?.message || e?.message,
          duration: 15,
        });
        //handleClosed();
        setSubmittingOTP(false);
      }
    } else {
      notification.error({
        message: 'Please ensure all required fields are entered',
      });
    }
  };

  const handleSubmit = () => {
    if (seatArray.length <= 0) {
      notification.error({
        message: 'Please select a seat to continue',
        duration: 10,
      });
    } else if (!phone) {
      notification.error({
        message: 'Phone is required',
        duration: 10,
      });
    } else if (!ice_phone) {
      notification.error({
        message: 'ICE Phone is required',
        duration: 10,
      });
    } else {
      form
        .validateFields(required[0])
        .then(async (v) => {
          //console.log(v);
          if (schedulDetails?.prepaid) {
            try {
              setSubmitting(true);
              //const values = form.getFieldsValue();
              //console.log(values);
              // const usr = JSON.parse(user);

              const payload = {
                tickets: seatArray.map((item) => ({
                  seat_id: item,
                  pickup_id: selectedMidRoute
                    ? selectedMidRoute?.value
                    : undefined,
                  schedule_id: Number(params?.id),
                  minor_count: Number(form.getFieldValue('minor_count')) || 0,
                  luggage_description: 'Guest user luggage',
                  occupant_type: 'ADULT',

                  passenger: {
                    first_name: form.getFieldValue('first_name'),
                    last_name: form.getFieldValue('last_name'),
                    phone: phone,
                    ice_phone: ice_phone,
                  },
                })),
                schedule_id: Number(params?.id),
                prepaid: true,
                wallet: {
                  account_name: form.getFieldValue('account_name'),
                  account_number: pay_phone,
                  account_provider: selectedProvider?.value,
                },
              };
              //console.log(payload);

              const { data } = await api.post(
                `${RouteConstants.BULK_GUEST_SCHEDULE}/${params?.id}/book-bulk`,
                {
                  ...payload,
                }
              );

              setTicketLink(data?.manifest?.code);
              setIsBusy(true);
              setSuccess(true);

              setSubmitting(false);
            } catch (e: any) {
              //console.log(e);
              setSubmitting(false);
              notification.error({
                message: e?.response?.data?.message,
              });
            }
          } else {
            if (pay_phone) {
              try {
                setSubmitting(true);
                //const values = form.getFieldsValue();
                //console.log(values);

                const payload = {
                  tickets: seatArray.map((item) => ({
                    seat_id: item,
                    pickup_id: selectedMidRoute
                      ? selectedMidRoute?.value
                      : undefined,
                    schedule_id: Number(params?.id),
                    minor_count: Number(form.getFieldValue('minor_count')) || 0,
                    luggage_description: 'Guest user luggage',
                    occupant_type: 'ADULT',

                    passenger: {
                      first_name: form.getFieldValue('first_name'),
                      last_name: form.getFieldValue('last_name'),
                      phone: phone,
                      ice_phone: ice_phone,
                    },
                  })),
                  schedule_id: Number(params?.id),
                  //prepaid: false,
                  wallet: {
                    account_name: form.getFieldValue('account_name'),
                    account_number: pay_phone,
                    account_provider: selectedProvider?.value,
                  },
                };
                //console.log(payload);

                const { data } = await api.post(
                  `${RouteConstants.BULK_GUEST_SCHEDULE}/${params?.id}/book-bulk`,
                  {
                    ...payload,
                  }
                );

                setPaymentId(data?.payload?.payment?.id);
                if (data?.payload?.payment?.auth?.type === 'OTP') {
                  setSocketToken(data?.payload?.payment?.realtime?.token);
                  setSocketUrl(data?.payload?.payment?.realtime?.endpoint);

                  setIsBusy(true);
                  setOTPRequired(true);
                } else {
                  setSubmitting(false);
                  setIsBusy(true);
                  setProcessing(true);

                  const socket = new WebSocket(
                    `${data?.payload?.payment?.realtime?.endpoint}?token=${data?.payload?.payment?.realtime?.token}`
                  );
                  setIsBusy(true);
                  setProcessing(true);
                  setSubmitting(false);
                  setIsCounting(true);

                  // Connection opened
                  socket.addEventListener('open', (event) => {
                    console.log('socket connection open');
                  });

                  // Listen for messages
                  socket.addEventListener('message', (event) => {
                    const data = JSON.parse(event.data);

                    if (data?.status?.code === 'SUCCESS') {
                      setSuccess(true);
                      setProcessing(false);
                      setIsCounting(false);
                      socket.close();
                    }

                    if (data?.status?.code === 'FAILED') {
                      setFailed(true);
                      setSuccess(false);
                      setProcessing(false);
                      setIsCounting(false);

                      socket.close();
                    }

                    //console.log('Message from server ', event.data);
                  });
                }
              } catch (e: any) {
                // console.log(e);
                setSubmitting(false);
                notification.error({
                  message: e?.response?.data?.message,
                });
              }
            } else {
              notification.error({
                message: 'The payment number field is required',
              });
            }
          }
        })
        .catch((e) => {
          notification.error({
            message: 'Fill required fields to continue',
          });
        });
    }
  };

  const handleSeatSelection = (seat: any, index: number) => {
    // const seatObj = busSeats.find((selectedSeat) => selectedSeat?.id === seat);
    // setSelectedSeat(seatObj);
    //console.log(seat);
    let newSeats = [...busSeats];

    const getSelectedSeat = newSeats.find((value) => value?.id === seat?.id);
    if (getSelectedSeat) {
      if (getSelectedSeat.selected === true) {
        newSeats[index].selected = false;
      } else {
        newSeats[index].selected = true;
      }
    }
    const findOne = seatArray.find((value) => value === seat?.id);

    const findSeatObj = seatObjArray.find((value) => value?.id === seat?.id);

    if (findSeatObj) {
      setSeatObjArray(
        seatObjArray.filter((selectedSeat) => selectedSeat?.id !== seat?.id)
      );
    } else {
      setSeatObjArray([...seatObjArray, seat]);
    }

    if (findOne) {
      setSeatArray(
        seatArray.filter((selectedSeat) => selectedSeat !== seat?.id)
      );
    } else {
      setSeatArray([...seatArray, seat?.id]);
    }
  };

  const getPickups = async (id: number) => {
    try {
      const filter = [
        {
          f: 'route.id',
          o: '=',
          p: [id],
        },
      ];

      const res = await api.get(
        `${RouteConstants.MIDROUTES}?filters=${JSON.stringify(filter)}`
      );

      setPickups(
        res.data?.payload?.items?.map((item: MidRoute) => ({
          label: item?.pickup?.name,
          value: item?.pickup?.id,
        }))
      );
    } catch (e) {
      //console.log(e);
    }
  };

  const getScheduleDetails = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `${RouteConstants.SINGLE_GUEST_SCHEDULE}/${params?.id}`
      );
      setScheduleDetails(res.data?.payload);
      if (res?.data?.payload?.mid_route === true) {
        getPickups(res.data?.payload?.route?.id);
      }
      setLoading(false);
    } catch (e: any) {
      //console.log(e);
      setLoading(false);
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
    }
  };

  const getScheduleSeats = async () => {
    setSeatsLoading(true);
    try {
      const res = await api.get(
        `${RouteConstants.SINGLE_GUEST_SCHEDULE}/${params?.id}/seats`
      );

      setBusSeats(
        res.data?.payload?.items?.map((item: CustomSeatType) => ({
          id: item?.id,
          number: item?.number,
          status: item?.status,
          selected: false,
        }))
      );
      setSeatsLoading(false);
    } catch (e) {
      //console.log(e);
      setSeatsLoading(false);
    }
  };

  const handleOpen = (value: any) =>
    setAccordionOpen(accordionOpen === value ? 0 : value);

  function Icon({ id, open }: any) {
    return (
      <svg
        className={`${
          id === open
            ? 'transition delay-150 -rotate-90'
            : 'transition delay-150'
        }  transition-transform`}
        xmlns='http://www.w3.org/2000/svg'
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
      >
        <path
          d='M26.5599 11.9333L17.8666 20.6267C16.8399 21.6533 15.1599 21.6533 14.1333 20.6267L5.43994 11.9333'
          stroke='#B40303'
          stroke-width='3'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    );
  }

  useEffect(() => {
    let timer: any;
    if (isCounting && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setFailed(true);
      setProcessing(false);
      setSuccess(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isCounting, countdown]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    //console.log(params);
    getScheduleDetails();
    getScheduleSeats();

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <PageLayout>
        <div className='mt-20 bg-white min-h-screen'>
          <div className='sm:flex justify-center pt-20 sm:gap-7 m-6 sm:m-0  w-full '>
            <div className='sm:w-[30%]'>
              <div className=' border rounded-md p-4 border-gray-200'>
                <Skeleton active paragraph round />
              </div>
              <div className='mt-5 border rounded-md p-4 border-gray-200'>
                <Skeleton active paragraph round />
              </div>
            </div>
            <div className='sm:w-[20%] rounded-m p-4 border border-gray-200'>
              <Skeleton active paragraph round />
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className='pt-[4rem] px-[1rem] sm:px-[8rem] bg-white min-h-screen'>
        <Spin
          spinning={submitting}
          className='py-10 md:py-20 min-h-[50vh] flex justify-center items-center'
          indicator={
            <Loader
              float
              bgColor='transparent appear'
              width='w-16'
              height='h-16'
            />
          }
        >
          <Signin
            isOpen={openLogIn}
            handleClosed={() => setOpenLogIn(false)}
            openSignUp={() => setOpenRegister(true)}
          />

          <SignUp
            isOpen={openRegister}
            handleClosed={() => setOpenRegister(false)}
            openSignIn={() => setOpenLogIn(true)}
          />

          <Modal open={isBusy} footer={null} closable={false}>
            {otp_required && (
              <Form form={form} layout='vertical' className='appear'>
                <div className='pb-4'>
                  <div className='font-semibold text-[20px] text-center'>
                    Verify Payment Mobile Number
                  </div>
                  <div>Enter OTP sent to your number</div>
                </div>
                <Form.Item
                  colon={false}
                  label='OTP'
                  name='otp'
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <div className='flex justify-center mt-4 mb-3'>
                    <img src={verifyImg} alt='verify' />
                  </div>
                  <Input
                    size='large'
                    disabled={submitting}
                    placeholder='000000'
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </Form.Item>
                <div className='text-right flex items-end gap-3 justify-end'>
                  {submittingOTP ? (
                    <CustomButton
                      data-testid='submitPay'
                      disabled={submittingOTP}
                    >
                      <Loader width='w-6' height='h-6' />
                    </CustomButton>
                  ) : (
                    <CustomButton
                      data-testid='pay'
                      onClick={() => submitOtp(paymentId)}
                    >
                      Pay
                    </CustomButton>
                  )}
                  <CustomButton
                    data-testid='cancelPay'
                    variant='secondary'
                    onClick={() => {
                      setIsBusy(false);
                      setOTPRequired(false);
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </CustomButton>
                </div>
              </Form>
            )}
            {processing && (
              <div>
                <div className='flex justify-center mt-4'>
                  {/* <img src={processingImg} alt='processing' /> */}
                  <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
                    Processing payment
                  </div>
                </div>

                <div className='text-center py-3'>
                  You will receive a prompt to enter your Pin to complete this
                  transaction.
                </div>
                <div className='text-center text-[20px] py-4'>{countdown}</div>
                <Divider className='my-4' />
                <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
                  Processing...
                </div>
              </div>
            )}

            {failed && (
              <div>
                <div className='w-full flex justify-end items-end'>
                  <button
                    data-testid='failedGuestPayment'
                    onClick={() => {
                      navigate(-1);
                      setIsBusy(false);
                      setSuccess(false);
                    }}
                  >
                    <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                  </button>
                </div>

                <div className='flex items-center gap-3'>
                  <XMarkIcon className='w-12 h-12 text-red-500' />
                  <div className='text-center text-[24px] font-semibold text-red-500 py-1'>
                    Payment Failed
                  </div>
                </div>
                <div className='flex items-center gap-1'>
                  <div className='text-center py-3 text-lg'>
                    We could not process your payment. Try again later.
                  </div>
                </div>
                <CustomButton
                  data-testid='closeGuestPayment'
                  onClick={() => {
                    //setStillProcessing(false);
                    setIsBusy(false);
                    setFailed(false);
                    navigate(-1);
                  }}
                >
                  Close
                </CustomButton>
              </div>
            )}
            {stilProcessing && (
              <div>
                <div className='w-full flex justify-end items-end'>
                  <button
                    data-testid='processingGuestPayment'
                    onClick={() => {
                      navigate(-1);
                      setIsBusy(false);
                      setSuccess(false);
                    }}
                  >
                    <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                  </button>
                </div>
                <div className='flex justify-center mt-4 items-center gap-3'>
                  <img src={loadingImg} alt='processing' />
                </div>

                <div className='flex justify-between items-center'>
                  <div className='text-center py-3 text-base'>
                    This is taking longer than expected. This could be as a
                    result of the payment provider's prompt failing.
                  </div>
                </div>

                <button
                  className='text-oya-red-50 font-semibold hover:text-red-400'
                  data-testid='dismissGuestPayment'
                  onClick={() => {
                    //setStillProcessing(false);
                    setIsBusy(false);
                    setStillProcessing(false);
                    navigate(-1);
                  }}
                >
                  Dismiss
                </button>
              </div>
            )}

            {success && (
              <div className='success-popup'>
                <div className='w-full flex justify-end items-end'>
                  <button
                    data-testid='guestPaymentSuccess'
                    onClick={() => {
                      navigate(-1);
                      setIsBusy(false);
                      setSuccess(false);
                    }}
                  >
                    <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                  </button>
                </div>
                <div className='flex flex-col justify-center mt-4 items-center gap-3'>
                  {/* <img src={successImg} alt='processing' /> */}
                  <div className='border-[4px] rounded-full w-[63px] h-[63px] border-seat-green flex items-center justify-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='25'
                      height='18'
                      viewBox='0 0 25 18'
                      fill='none'
                    >
                      <path
                        d='M1.5 9.29247L8.79247 16.5849L23.34 2'
                        stroke='#2ABB7F'
                        stroke-width='2.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                  <div className='text-center text-[24px] font-semibold text-seat-green py-2'>
                    Booking successful!
                  </div>
                </div>
                <div className='text-[16px] text-center'>
                  Your trip has been booked successfully.
                </div>
                <div className='text-oya-red-50 text-center text-[16px]'>
                  {ticketLink && (
                    <button
                      data-testid='viewTicket'
                      onClick={() =>
                        window.open(
                          `${PORTAL_URL}/ticket?tn=${ticketLink}`,
                          '_blank'
                        )
                      }
                    >
                      View ticket
                    </button>
                  )}
                </div>
                {!token && (
                  <>
                    <Divider />
                    <div className='text-center text-[16px]'>
                      Sign up with Oya to{' '}
                      <span className='text-oya-red-50'>
                        see your trip history
                      </span>
                      , <span className='text-oya-red-50'>rate your trip</span>,
                      <span className='text-oya-red-50'>report incidents</span>{' '}
                      and{' '}
                      <span className='text-oya-red-50'>share your trip</span>
                    </div>

                    <div className='flex justify-center items-center gap-4 mt-4'>
                      <CustomButton
                        data-testid='createAccount'
                        onClick={() => {
                          setIsBusy(false);
                          setSuccess(false);
                          setOpenRegister(true);
                        }}
                      >
                        Create account
                      </CustomButton>
                      <CustomButton
                        data-testid='login'
                        onClick={() => {
                          setIsBusy(false);
                          setSuccess(false);
                          setOpenLogIn(true);
                        }}
                        variant='secondary'
                      >
                        Login
                      </CustomButton>
                    </div>
                  </>
                )}
              </div>
            )}
          </Modal>
          <div>
            <button
              data-testid='arrowBack'
              className='mt-[2rem] mb-5 flex gap-3 items-center'
              onClick={() => navigate(-1)}
            >
              <ArrowBackIos
                sx={{
                  width: '20px',
                  height: '20px',
                  color: '#B40303',
                }}
              />
              <div className='text-oya-red-100 font-semibold text-lg'>Back</div>
            </button>

            <Form layout='vertical' form={form}>
              {schedulDetails ? (
                <div className='lg:flex flex-wrap gap-6'>
                  <div className='flex-[0.6] mb-8'>
                    {/* TripDetails */}
                    <Accordion
                      open={accordionOpen === 1}
                      icon={<Icon id={1} open={accordionOpen} />}
                    >
                      <AccordionHeader
                        onClick={() => handleOpen(1)}
                        className='border-none'
                      >
                        <div className='flex items-center font-semibold text-oya-red-100 gap-5 text-[24px]'>
                          <h2>1</h2>
                          <h2>Trip details</h2>
                        </div>
                      </AccordionHeader>
                      <AccordionBody>
                        {accordionOpen === 1 && (
                          <div className='card-container mb-3 lg:mx-8'>
                            <div className='flex justify-between'>
                              <div>
                                <h1 className='tracking-tight'>
                                  {schedulDetails?.route?.from?.name} -{' '}
                                  {schedulDetails?.route?.to?.name}
                                </h1>
                                <p className='py-'>
                                  {moment(schedulDetails?.departures_at).format(
                                    'MMMM Do, YYYY'
                                  )}
                                </p>
                              </div>
                              <div>
                                <div>
                                  <p>{schedulDetails?.branch?.name}</p>
                                </div>
                                <div>
                                  <p>{schedulDetails?.bus?.reg_number}</p>
                                  {!schedulDetails?.prepaid && (
                                    <p>
                                      {schedulDetails?.price?.currency}{' '}
                                      {Intl.NumberFormat('en-US').format(
                                        Number(schedulDetails?.price?.amount)
                                      )}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <Form.Item
                              name='minor_count'
                              className='pt-3'
                              label={
                                <div className='text-sm text-oya-gray'>
                                  How many minors are traveling with you?
                                </div>
                              }
                            >
                              <InputNumber min={0} defaultValue={0} />
                            </Form.Item>
                            <div>
                              <Form.Item
                                label='Where will you join the trip?'
                                name='pickup'
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                ]}
                              >
                                <Radio.Group
                                  size='large'
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (
                                      value === 0 &&
                                      form.getFieldValue('pickup_id')
                                    )
                                      form.setFieldsValue({
                                        pickup_id: null,
                                      });
                                    setSelectedPickup(value);
                                  }}
                                  data-testid='buy-ticket-pickup-radio-button'
                                >
                                  <Space direction='vertical'>
                                    <Radio className='pickup__radio' value={0}>
                                      Bus Terminal
                                    </Radio>
                                    <Radio
                                      className='pickup__radio'
                                      value={1}
                                      disabled={pickups.length <= 0}
                                    >
                                      Mid Route
                                    </Radio>
                                  </Space>
                                </Radio.Group>
                                {pickups.length <= 0 && (
                                  <div className='text-oya-secondary-red'>
                                    This trip does not support joining midway.
                                  </div>
                                )}
                              </Form.Item>
                              {selectedPickup === 1 && (
                                <div className='w-full md:w-2/3'>
                                  <Form.Item
                                    colon={false}
                                    label={
                                      <div className='font-medium text-oya-ghana-header-h7'>
                                        Your pickup point
                                      </div>
                                    }
                                    name='pickup_id'
                                  >
                                    <Select
                                      options={pickups}
                                      onChange={(e) => {
                                        setSelectedMidRoute(e);
                                        console.log(e);
                                      }}
                                    />
                                  </Form.Item>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </AccordionBody>
                    </Accordion>

                    <Divider className='border-oya-gray mt-2' />

                    {/* Personal Info */}
                    <Accordion
                      placeholder=''
                      open={accordionOpen === 2}
                      icon={<Icon id={2} open={accordionOpen} />}
                    >
                      <AccordionHeader
                        onClick={() => handleOpen(2)}
                        className='border-none'
                      >
                        <div className='flex mt-4 items-center font-semibold text-oya-red-100 gap-5 text-[24px]'>
                          <h2>2</h2>
                          <h2>Personal info</h2>
                        </div>
                      </AccordionHeader>
                      <AccordionBody key={2}>
                        {accordionOpen === 2 && (
                          <div className='card-container mb-3 lg:mx-8'>
                            <div className='flex gap-3 items-center'>
                              <Form.Item
                                colon={false}
                                className='w-1/2'
                                label='First name'
                                name='first_name'
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                  {
                                    validator: validateUserName,
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Doe' />
                              </Form.Item>
                              <Form.Item
                                colon={false}
                                className='w-1/2'
                                label='Last name'
                                name='last_name'
                                rules={[
                                  {
                                    required: true,
                                    message: 'This field is required',
                                  },
                                  {
                                    validator: validateUserName,
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Kweku' />
                              </Form.Item>
                            </div>
                            <Form.Item
                              colon={false}
                              label='Phone number'
                              name='phone'
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              <PhoneInput
                                value={phone}
                                onChange={(e) => {
                                  let formatted = e?.replace(/\s+/g, '');
                                  setPhone(formatted);
                                }}
                                placeholder='0244123456'
                                className='phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none'
                                defaultCountry='GH'
                              />
                            </Form.Item>
                            <Form.Item
                              colon={false}
                              label='Primary Emergency Contact'
                              name='ice_phone'
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                },
                              ]}
                            >
                              <PhoneInput
                                value={ice_phone}
                                onChange={(e) => {
                                  let formatted = e?.replace(/\s+/g, '');
                                  setICEPhone(formatted);
                                }}
                                placeholder='0244123456'
                                className='phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none'
                                defaultCountry='GH'
                              />
                            </Form.Item>
                          </div>
                        )}
                      </AccordionBody>
                    </Accordion>
                    <Divider className='border-oya-gray mt-2' />

                    {/* Seat selection */}
                    <Accordion
                      placeholder=''
                      open={accordionOpen === 3}
                      icon={<Icon id={3} open={accordionOpen} />}
                    >
                      <AccordionHeader
                        onClick={() => handleOpen(3)}
                        className='border-none'
                      >
                        <div className='flex mt-4 items-center font-semibold text-oya-red-100 gap-5 text-[24px]'>
                          <h2>3</h2>
                          <h2>Select seat</h2>
                        </div>
                      </AccordionHeader>
                      <AccordionBody>
                        {accordionOpen === 3 && (
                          <div className='card-container mb-3 lg:mx-8'>
                            <div>
                              <Form.Item name='seat_id'>
                                {!isLarge && (
                                  <div className='mb-4'>
                                    <div className='flex gap-5 items-center mb-3'>
                                      <div className='w-5 h-5 bg-green-600' />
                                      <div>Available</div>
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                      <div className='w-5 h-5 bg-oya-ghana-red' />
                                      <div>Unavailable</div>
                                    </div>
                                  </div>
                                )}
                                {busSeats.length > 0 || seatsLoading ? (
                                  <>
                                    <div className='sm:flex gap-5'>
                                      <div className='grid grid-cols-4 '>
                                        {busSeats.map((seat, index) => (
                                          <button
                                            key={seat?.id}
                                            //value={seat}
                                            className={`pt-2 m-2 ${
                                              seat?.selected
                                                ? 'bg-oya-red-20'
                                                : ''
                                            }`}
                                            disabled={seat?.status === 1}
                                            onClick={(e) => {
                                              handleSeatSelection(seat, index);
                                            }}
                                          >
                                            <div className=' relative flex items-center justify-center'>
                                              <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                className={`${
                                                  seat?.status === 1
                                                    ? 'text-oya-red-100'
                                                    : 'text-seat-green'
                                                }`}
                                                width='44'
                                                height='39'
                                                viewBox='0 0 54 49'
                                                fill='none'
                                              >
                                                <rect
                                                  y='6.23016'
                                                  width='5.25'
                                                  height='37.1907'
                                                  rx='2.625'
                                                  className='fill-current'
                                                  fill='none'
                                                />
                                                <rect
                                                  x='48.75'
                                                  y='6.23016'
                                                  width='5.25'
                                                  height='37.1907'
                                                  rx='2.625'
                                                  className='fill-current'
                                                  fill='none'
                                                />
                                                <path
                                                  d='M10.5 5.51144C10.5 2.95824 12.4242 0.806375 14.9672 0.579164C23.7952 -0.209571 30.1884 -0.180242 39.0114 0.591414C41.5634 0.814609 43.5 2.96993 43.5 5.53167V29.704C43.5 32.064 41.8493 34.1184 39.5261 34.5334C30.5267 36.1411 23.962 36.0686 14.5616 34.4894C12.1998 34.0926 10.5 32.0214 10.5 29.6265V5.51144Z'
                                                  className='fill-current'
                                                  fill='none'
                                                />
                                                <path
                                                  d='M10.5 42.7474C10.5 40.0977 13.0312 38.1936 15.6164 38.7748C23.981 40.6551 30.1751 40.6137 38.3947 38.766C40.9761 38.1858 43.5 40.0888 43.5 42.7347V43.8398C43.5 45.7559 42.1406 47.4108 40.2522 47.7352C30.5438 49.4033 23.8265 49.4414 13.7562 47.7303C11.8644 47.4089 10.5 45.7524 10.5 43.8335V42.7474Z'
                                                  className='fill-current'
                                                  fill='none'
                                                />
                                              </svg>

                                              <span className='text-white text-oya-ghana-header-h7 absolute text-center pb-2'>
                                                {seat?.number}
                                              </span>
                                            </div>
                                          </button>
                                        ))}
                                      </div>
                                      {isLarge && (
                                        <div>
                                          <div className='flex gap-5 items-center mb-3'>
                                            <div className='w-5 h-5 bg-seat-green' />
                                            <div>Available</div>
                                          </div>
                                          <div className='flex gap-2 items-center'>
                                            <div className='w-5 h-5 bg-oya-red-100' />
                                            <div>Unavailable</div>
                                          </div>
                                          {/* <div>
                                            {selectedSeat && (
                                              <button
                                                data-testid='clearSeatSelection'
                                                className='text-oya-red-50 font-semibold mt-10'
                                                onClick={() =>
                                                  setSelectedSeat(undefined)
                                                }
                                              >
                                                Clear selection
                                              </button>
                                            )}
                                          </div> */}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    <div>
                                      No seats available for this schedule
                                    </div>
                                  </div>
                                )}
                              </Form.Item>
                            </div>
                          </div>
                        )}
                      </AccordionBody>
                    </Accordion>

                    {!schedulDetails?.prepaid && (
                      <>
                        <Divider className='border-oya-gray mt-2' />
                        {/* Payment info */}
                        <Accordion
                          open={accordionOpen === 4}
                          icon={<Icon id={4} open={accordionOpen} />}
                          placeholder=''
                        >
                          <AccordionHeader
                            onClick={() => handleOpen(4)}
                            className='border-none'
                          >
                            <div className='flex mt-4 items-center font-semibold text-oya-red-100 gap-5 text-[24px]'>
                              <h2>4</h2>
                              <h2>Payment info</h2>
                            </div>
                          </AccordionHeader>
                          <AccordionBody>
                            {accordionOpen === 4 && (
                              <div className='card-container mb-3 lg:mx-8'>
                                <Form.Item
                                  colon={false}
                                  label='Choose Payment Network'
                                  name='account_provider'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                  {/* <Select
                                    size='large'
                                    placeholder='Network'
                                    onChange={(e) => {
                                      let findOne = networks.find(
                                        (value) => value?.code === e
                                      );
                                      setSelectedProvider(findOne);
                                    }}
                                  >
                                    {networks.map((net, index) => (
                                      <Select.Option
                                        key={index}
                                        value={net?.code}
                                      >
                                        {net?.name}
                                      </Select.Option>
                                    ))}
                                  </Select> */}
                                  <Select
                                    options={networks}
                                    onChange={(e) => {
                                      setSelectedProvider(e);
                                    }}
                                    placeholder='Network'
                                  />
                                </Form.Item>

                                <Form.Item
                                  colon={false}
                                  label='Mobile Money Number'
                                  name='account_number'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                  <PhoneInput
                                    value={pay_phone}
                                    onChange={(e) => {
                                      let formatted = e?.replace(/\s+/g, '');
                                      setPaymentPhone(formatted);
                                    }}
                                    placeholder='0244123456'
                                    className='phone bg-white w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none'
                                    defaultCountry='GH'
                                  />
                                </Form.Item>

                                <Form.Item
                                  colon={false}
                                  label='Mobile Money Name'
                                  name='account_name'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                    {
                                      validator: validateUserName,
                                    },
                                  ]}
                                >
                                  <Input size='large' placeholder='Full name' />
                                </Form.Item>
                              </div>
                            )}
                          </AccordionBody>
                        </Accordion>
                      </>
                    )}
                  </div>

                  {/* Sumary */}
                  <div className='flex-[0.4] '>
                    <div className='card-container p-3 lg:fixed lg:w-[393px]'>
                      <div className='text-xl font-semibold'>Trip summary</div>

                      <div className='my-3'>
                        <div className='text-oya-red-50'>Trip details</div>
                        <div className='flex justify-between items-center'>
                          <div>Minor(s)</div>
                          <div>
                            {form.getFieldValue('minor_count')
                              ? form.getFieldValue('minor_count')
                              : 0}
                          </div>
                        </div>
                        <div className='flex justify-between items-center'>
                          <div>Boarding</div>
                          <div>
                            {selectedMidRoute
                              ? selectedMidRoute?.label
                              : 'Bus Terminal'}
                          </div>
                        </div>
                        <div className='flex justify-between items-center'>
                          <div>Seat Number(s)</div>

                          {seatObjArray?.length > 0 ? (
                            <div className='font-semibold flex gap-2 flex-wrap justify-end w-1/2 items-end text-right'>
                              {seatObjArray?.map((item, index) => (
                                <div key={index} className='flex'>
                                  <div>{item?.number}</div>
                                  <div>
                                    {index < seatObjArray?.length - 1
                                      ? ', '
                                      : ''}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>No Seat selected</div>
                          )}
                        </div>
                      </div>
                      <Divider className='border-oya-gray mt-2' />
                      {/* Personal info */}
                      <div>
                        <div className='text-oya-red-50'>Personal info</div>
                        <div className='flex justify-between items-center'>
                          <div>Name</div>
                          {form.getFieldValue('first_name') &&
                            form.getFieldValue('last_name') && (
                              <div>
                                {form.getFieldValue('first_name')}{' '}
                                {form.getFieldValue('last_name')}
                              </div>
                            )}
                        </div>
                        <div className='flex justify-between items-center'>
                          <div>Phone</div>
                          {phone && <div>{phone}</div>}
                        </div>
                        <div className='flex justify-between items-center'>
                          <div>Primary Emergency Contact</div>
                          {ice_phone && <div>{ice_phone}</div>}
                        </div>
                      </div>
                      {!schedulDetails?.prepaid && (
                        <>
                          <Divider className='border-oya-gray mt-2' />
                          <div>
                            <div className='text-oya-red-50'>
                              Payment details
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Payment provider</div>
                              <div>
                                {selectedProvider && (
                                  <div>{selectedProvider?.label}</div>
                                )}
                              </div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Payment number</div>
                              <div>{pay_phone && <div>{pay_phone}</div>}</div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Payment name</div>
                              <div>
                                {form.getFieldValue('account_name') && (
                                  <span>
                                    {form.getFieldValue('account_name')}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {!schedulDetails?.prepaid && (
                        <>
                          <Divider />
                          <div className='my-3'>
                            <div className='flex justify-between items-center font-semibold'>
                              <div>Price for a ticket</div>
                              {schedulDetails && (
                                <div className='flex gap-2 items-center'>
                                  <div>{schedulDetails?.price?.currency}</div>
                                  {seatArray.length > 0 ? (
                                    <span>
                                      {Intl.NumberFormat('en-US').format(
                                        Number(schedulDetails?.price?.amount) *
                                          seatArray.length
                                      )}
                                    </span>
                                  ) : (
                                    <span>
                                      {Intl.NumberFormat('en-US').format(
                                        Number(schedulDetails?.price?.amount)
                                      )}
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className='text-xs text-gray-500 py-3'>
                              *Transaction charges may apply.
                            </div>
                          </div>
                        </>
                      )}

                      <CustomButton
                        data-testid='bookAndPay'
                        type='button'
                        width='w-full'
                        onClick={() => {
                          ReactGA.event({
                            category: `${process.env.NODE_ENV} Guest Buy Ticket`,
                            action: 'Book and pay',
                          });
                          handleSubmit();
                        }}
                      >
                        {schedulDetails?.prepaid ? (
                          <div>Book this trip</div>
                        ) : (
                          <div>Pay to book trip</div>
                        )}
                      </CustomButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col mt-16 justify-center items-center '>
                  <div className='flex flex-col justify-center items-center shadow-sm rounded-md border p-4'>
                    <InformationCircleIcon className='w-20 h-20 text-oya-red-100' />
                    <div className='py-4 sm:text-2xl text-xl'>
                      Schedule not found
                    </div>
                    <CustomButton
                      data-testid='goBackHome'
                      onClick={() => navigate('/')}
                    >
                      Go back home
                    </CustomButton>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </Spin>
      </div>
    </PageLayout>
  );
};

export default GuestBooking;
