import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { KostI18nProvider } from "kost-i18n";
import ENGLISH_TRANSLATIONS from './internationalisation/locales/default.en.json';
import FRENCH_TRANSLATIONS from './internationalisation/locales/default.fr.json';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <KostI18nProvider
        languages={[
          { key: 'en', name: 'English', translations: ENGLISH_TRANSLATIONS },
          { key: 'fr', name: 'French', translations: FRENCH_TRANSLATIONS }
        ]}
      >
        <App />
      </KostI18nProvider>
    </Provider>
  </React.StrictMode>
);
