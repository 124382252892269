import {
  ArrowRightIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  // PlusIcon,
} from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  //  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { RouteConstants } from "../../helpers/constants";
import { api } from "../../helpers/api";
import { checkFieldsComplete, getCookie } from "../../helpers/utils";
import { IOccupant, ISeat, PickupType, Schedule, Seat } from "../../types";
import moment from "moment";
import SeatModal from "../components/SeatModal";
import { Spinner } from "@material-tailwind/react";
import {
  Skeleton,
  Divider,
  Radio,
  Form,
  Input,
  Switch,
  notification,
} from "antd";
import PhoneInput from "react-phone-number-input";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import seat from "../../assets/img/seat_red.png";
import RemoveSeatModal from "../components/RemoveSeatModal";
import MidrouteModal from "../components/MidrouteModal";
import { db } from "../../types/db";

type Props = {};

function Icon({ id, open }: any) {
  return (
    <ChevronRightIcon
      className={`text-oya-red-100 w-5 h-5 ${
        id === open ? "transition delay-150 rotate-90" : "transition delay-150"
      }  transition-transform`}
    />
  );
}

const TripDetails = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const user: any = getCookie("oya_user_info");
  const userObj = JSON.parse(user);

  const [form] = Form.useForm();

  const [schedule, setSchedule] = useState<Schedule | undefined>();
  const [showSeatModal, setShowSeatModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seats, setSeats] = useState<ISeat[]>([]);

  const [showRemoveSeat, setShowRemoveSeat] = useState(false);
  const [showPickupModal, setShowPickupModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [accordionOpen, setAccordionOpen] = useState(0);

  const token = getCookie("oya_token");

  const [seatIndex, setSeatIndex] = useState<any>();
  const [selectedMidRoute, setSelectedMidroute] = useState<any>(null);
  const [formValues, setFormValues] = useState({});
  const handleSelectedSeat = async (items: Array<Seat>) => {
    const itemsArray = items.map((seat, index) => ({
      index: index,
      seat_id: seat?.id,
      seat_num: seat?.number,
      schedule_id: schedule?.id,
      minors: 0,
      occupant_type: "ADULT",
      pickup_name: "BUS-TERMINAL",
      shareWithMinor: false,
      pickup_id: undefined,
      isComplete: false,
      passenger: {
        first_name: "",
        last_name: "",
        phone: "",
        ice_phone: "",
      },
    }));
    setSeats(itemsArray);

    try {
      setLoading(true);
      await db.transaction("rw", db.seats, async () => {
        await db.seats.bulkAdd(itemsArray);
      });
      getUserSeats();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: `${error}`,
      });
    }
  };

  const getUserSeats = async () => {
    try {
      const res = await db.seats.toArray();
      if (res.length > 0) {
        setSeats(res);
      }
      //console.log("seats", res);
    } catch (error) {
      console.log(error);
    }
  };

  const getScheduleInfo = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `${RouteConstants.SCHEDULES}/${params?.id}/details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSchedule(res.data?.payload);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSeatPurposeChange = async (
    index: number,
    typeName: IOccupant
  ) => {
    try {
      await db.seats.update(index, { occupant_type: typeName });
      getUserSeats();
    } catch (error) {
      console.log("Error updating");
    }
  };

  const handlePickupChange = (
    index: number,
    value: number | undefined,
    pickupName: PickupType
  ) => {
    const newDetails = seats.map((newItem) =>
      newItem.index === index
        ? {
            ...newItem,
            pickup_id: value,
            pickup_name: pickupName,
          }
        : newItem
    );

    setSeats(newDetails);
  };

  const handleRemoveSeat = async (index: number) => {
    console.log("Index to remove", index);
    try {
      await db.seats.delete(index);
      getUserSeats();
    } catch (error) {
      console.log("error deleting");
    }
  };

  const handleSelectedMidroute = async (
    index: number,
    value: any,
    pickupType: PickupType
  ) => {
    setSelectedMidroute(value);
    console.log("Index to add midroute", index);
    try {
      await db.seats.update(index, {
        pickup_id: value?.value,
        pickup_name: pickupType,
        selected_pickup: value?.label,
      });
      getUserSeats();
    } catch (error) {
      console.log("error updating");
    }
  };

  const handleSubmit = async () => {
    //create a list of tickets with the required
    //fields
    //
    form
      .validateFields()
      .then(async () => {
        //setIsFilled(true);
        if (seats.length > 0) {
          try {
            if (!token) {
              notification.info({
                message: "Sign in to use this feature",
              });
            } else {
              setSubmitting(true);

              const payload = seats.map((item) => ({
                seat_id: item?.seat_id,
                pickup_id: item?.pickup_id && item?.pickup_id,
                schedule_id: item?.schedule_id,
                minors: item?.minors,
                luggage_description: item?.luggage_description
                  ? item?.luggage_description
                  : undefined,
                occupant_type: item?.occupant_type,
                passenger: {
                  first_name:
                    item?.occupant_type === "LAGGAGE"
                      ? userObj?.first_name
                      : item?.passenger?.first_name,
                  last_name:
                    item?.occupant_type === "LAGGAGE"
                      ? userObj?.last_name
                      : item?.passenger?.last_name,
                  phone: item?.passenger?.phone,
                  ice_phone:
                    item?.occupant_type === "LAGGAGE"
                      ? userObj?.ice1_phone
                      : item?.passenger?.ice_phone,
                },
              }));

              console.log("Payload", payload);

              //await db.seats.clear();

              // await db.transaction("rw", db.seats, async () => {
              //   await db.seats.bulkAdd(seats);
              // });
              // const res = await api.post(
              //   RouteConstants.TICKETS,
              //   {
              //     tickets: payload,
              //   },
              //   {
              //     headers: {
              //       Authorization: `Bearer ${token}`,
              //     },
              //   }
              // );
              //const data = seats;

              localStorage.setItem("details", JSON.stringify(schedule));
              // localStorage.setItem(
              //   "ticket",
              //   JSON.stringify(res?.data?.payload)
              // );
              navigate(`/trips/${schedule?.id}/summary`);
              setSubmitting(false);
            }
          } catch (error: any) {
            setSubmitting(false);
            notification.error({
              message: error?.response?.data?.message || error?.message,
            });
          }
        } else {
          notification.info({
            message: "Please select at least one seat to continue",
          });
        }
      })
      .catch((e) => {
        notification.error({
          message: "Fill required fields to continue",
        });
      });
  };

  const handleOpen = async (value: any) => {
    let editedSeats = [...seats];

    const findOne = editedSeats.find((item) => item.index === accordionOpen);

    const isFilled = checkFieldsComplete(findOne);

    if (findOne) {
      try {
        await db.seats.update(accordionOpen, {
          isComplete: isFilled,
          luggage_description:
            findOne?.occupant_type === "LAGGAGE" &&
            form.getFieldValue(`description_${accordionOpen}`) &&
            form.getFieldValue(`description_${accordionOpen}`),

          passenger: {
            first_name:
              findOne?.occupant_type === "LAGGAGE"
                ? userObj?.first_name
                : form.getFieldValue(`passenger_first_name_${accordionOpen}`),
            last_name:
              findOne?.occupant_type === "LAGGAGE"
                ? userObj?.last_name
                : form.getFieldValue(`passenger_last_name_${accordionOpen}`),
            ice_phone:
              findOne?.occupant_type === "LAGGAGE"
                ? userObj?.ice1_phone
                : form.getFieldValue(`passenger_ice_${accordionOpen}`),
            phone:
              findOne?.occupant_type === "MINOR"
                ? userObj?.phone
                : form.getFieldValue(`passenger_phone_${accordionOpen}`),
          },
        });
        getUserSeats();
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    }

    // if (isFilled) {
    //   editedSeats[accordionOpen].isComplete = true;
    //   setSeats(editedSeats);

    // }else{

    // }
    setAccordionOpen(accordionOpen === value ? 0 : value);
  };

  const handleFormInfo = (changedValues: any, allValues: any) => {
    const formInfo = form.getFieldsValue();
    setFormValues(allValues);
    localStorage.setItem("formData", JSON.stringify(formInfo));
    console.log(formInfo)
  };

  useEffect(() => {
    //getNetworkProviders();
    getScheduleInfo();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserSeats();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const storedValues = localStorage.getItem("formData") || "";
    if (!storedValues) {
    } else {
      form.setFieldsValue(JSON.parse(storedValues));
    }
  }, [form]);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={"#B40303"}
        />
        <meta name="theme-color" content={"#fff"} />
      </Helmet>
      <div className="bg-[#fff] relative min-h-screen overflow-hidden">
        <div className="top-0 fixed z-[100] w-full">
          <div className="relative w-full inline-flex">
            <div className="bg-white h-[44px] w-full pt-5 pb-3 px-[15px] ">
              <div className="flex items-center">
                <div className="flex-[0.3]">
                  <button
                    onClick={() => {
                      navigate(-1);
                      localStorage.removeItem("formData");
                    }}
                  >
                    <ChevronLeftIcon className="w-7 h-7 " />
                  </button>
                </div>
                <div className="flex-[0.7]">
                  <div className="header-text-3">Trip details</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {params?.id ? (
          <div className=" mt-[8rem]">
            <div className="px-5 min-h-screen overflow-y-auto">
              {loading ? (
                <div className="shadow-md rounded-lg bg-white p-2">
                  <Skeleton paragraph active />
                </div>
              ) : (
                <div className="shadow-md rounded-lg bg-white p-2">
                  <div className="flex justify-between items-center">
                    <div className="text-oya-gray-200 text-[10px]">
                      {schedule?.branch?.name}
                    </div>
                    {schedule?.premium && (
                      // <div className='premium-container text-oya-red-100 font-semibold'>
                      //   Premium
                      // </div>
                      <div className="bg-[#FFF7D6] p-2 rounded-md">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M1.33334 10.1933V3.80667C1.33334 2.92 1.84668 2.70667 2.47334 3.33333L4.20001 5.06C4.46001 5.32 4.88668 5.32 5.14001 5.06L7.52668 2.66667C7.78668 2.40667 8.21334 2.40667 8.46668 2.66667L10.86 5.06C11.12 5.32 11.5467 5.32 11.8 5.06L13.5267 3.33333C14.1533 2.70667 14.6667 2.92 14.6667 3.80667V10.2C14.6667 12.2 13.3333 13.5333 11.3333 13.5333H4.66668C2.82668 13.5267 1.33334 12.0333 1.33334 10.1933Z"
                            fill="#F5CD47"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  <div className="mt-2 flex gap-3 items-center">
                    <div className="header-text-4">
                      {schedule?.route?.from?.name}
                    </div>
                    <div>
                      <ArrowRightIcon className="w-6 h-6 text-oya-red-50" />
                    </div>
                    <div className="header-text-4">
                      {schedule?.route?.to?.name}
                    </div>
                  </div>
                  <div className="text-oya-gray-200 pt-1 text-sm">
                    {moment(schedule?.departures_at).format("LL")}
                  </div>

                  <div className="mt-4 flex justify-between items-center">
                    <div className="text-oya-red-50 text-[10px]">
                      {schedule?.bus?.type?.name}{" "}
                      {schedule?.loading_point?.label
                        ? ` (${schedule?.loading_point?.label})`
                        : ""}
                    </div>
                    <div className="flex gap-1 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <rect
                          y="1.94004"
                          width="1.16667"
                          height="8.26459"
                          rx="0.583333"
                          fill="#FF8380"
                        />
                        <rect
                          x="10.8333"
                          y="1.94004"
                          width="1.16667"
                          height="8.26459"
                          rx="0.583333"
                          fill="#FF8380"
                        />
                        <path
                          d="M2.33333 2.5782C2.33333 1.55692 3.10263 0.690626 4.12155 0.621261C5.43851 0.531606 6.554 0.534738 7.86999 0.624399C8.89231 0.694053 9.66667 1.56159 9.66667 2.58629V6.43696C9.66667 7.38095 9.00812 8.21162 8.07343 8.34384C6.64375 8.5461 5.44301 8.53633 3.96045 8.33136C3.01151 8.20016 2.33333 7.36392 2.33333 6.40596V2.5782Z"
                          fill="#FF8380"
                        />
                        <path
                          d="M2.33333 10.2034C2.33333 9.54004 2.96616 9.0641 3.61457 9.20396C5.35157 9.57859 6.67783 9.57088 8.38513 9.20262C9.03403 9.06265 9.66667 9.53927 9.66667 10.2031C9.66667 10.6838 9.32561 11.0994 8.85154 11.1793C6.77332 11.5293 5.30357 11.5369 3.14827 11.1777C2.67442 11.0987 2.33333 10.6837 2.33333 10.2034Z"
                          fill="#FF8380"
                        />
                      </svg>
                      <div className="text-[#FF8380] text-[10px]">
                        {schedule?.passenger_capacity &&
                          schedule?.passenger_capacity -
                            schedule?.onboard_passengers}{" "}
                        of {schedule?.passenger_capacity} seats available
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {loading ? (
                <div className="border rounded-lg p-3 mt-6">
                  <Skeleton paragraph active />
                </div>
              ) : (
                <Form
                  className="w-full "
                  form={form}
                  layout="vertical"
                  autoComplete="false"
                  onValuesChange={handleFormInfo}
                  initialValues={formValues}
                >
                  <div className="border rounded-lg p-3 mt-6">
                    <div className="flex items-center justify-between">
                      <div className="text-oya-gray text-[14px] font-semibold">
                        Passenger details
                      </div>
                      {seats && seats?.length > 0 && (
                        <button
                          onClick={() => {
                            setShowSeatModal(true);
                          }}
                          className="text-oya-red-100 font-semibold text-[12px] flex items-center gap-1"
                        >
                          <div className="text-[16px]">+</div>
                          <div>Add seat</div>
                        </button>
                      )}
                      {seats && seats?.length > 0 ? null : (
                        <button
                          onClick={() => {setShowSeatModal(true); localStorage.removeItem("formData") }}
                          className="text-oya-red-100 font-semibold text-[12px] flex items-center gap-1"
                        >
                          <div className="text-[16px]">+</div>
                          <div>Add seat</div>
                        </button>
                      )}
                    </div>
                    {seats && seats?.length > 0 && (
                      <div>
                        {seats?.map((item, index: number) => (
                          <div key={item?.index}>
                            <Accordion
                              open={accordionOpen === item?.index}
                              icon={<Icon id={index} open={accordionOpen} />}
                              placeholder=""
                            >
                              <AccordionHeader
                                onClick={() => {
                                  handleOpen(item?.index);
                                }}
                                className="border-none"
                              >
                                <div className="flex items-center font-semibold text-oya-red-100 gap-5 text-[18px] sm:text-[24px]">
                                  <div className="w-5 h-5">
                                    <img src={seat} alt="seat" />
                                  </div>
                                  <div className="text-oya-red-100 text-[14px]">
                                    Seat {item?.seat_num}
                                  </div>
                                  <div>
                                    {item?.isComplete ? (
                                      <CheckIcon className="text-seat-green w-5 h-5" />
                                    ) : (
                                      <ExclamationCircleIcon className="text-seat-stale-yellow-500 w-5 h-5" />
                                    )}
                                  </div>
                                </div>
                              </AccordionHeader>
                              <Divider className="border-oya-gray-100 mt-2" />
                              <AccordionBody>
                                <div>
                                  <div className="text-oya-gray text-[12px] font-medium mb-2">
                                    Purpose of seat
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <button
                                      onClick={() => {
                                        handleSeatPurposeChange(
                                          item?.index,
                                          "ADULT"
                                        );
                                      }}
                                      className={`seatPurpose flex items-center border rounded-lg p-1 ${
                                        item.occupant_type === "ADULT"
                                          ? " border-oya-red-100"
                                          : "border-oya-gray-100"
                                      }`}
                                    >
                                      <Radio
                                        checked={
                                          item.occupant_type === "ADULT"
                                            ? true
                                            : false
                                        }
                                      />
                                      <div className="text-oya-gray text-[14px]">
                                        Adult
                                      </div>
                                    </button>
                                    <button
                                      onClick={() => {
                                        handleSeatPurposeChange(
                                          item?.index,
                                          "MINOR"
                                        );
                                      }}
                                      className={`seatPurpose flex items-center border rounded-lg p-1 ${
                                        item.occupant_type === "MINOR"
                                          ? " border-oya-red-100"
                                          : "border-oya-gray-100"
                                      }`}
                                    >
                                      <Radio
                                        checked={
                                          item.occupant_type === "MINOR"
                                            ? true
                                            : false
                                        }
                                      />
                                      <div className="text-oya-gray text-[14px]">
                                        Minor
                                      </div>
                                    </button>
                                    <button
                                      onClick={() => {
                                        handleSeatPurposeChange(
                                          item?.index,
                                          "LAGGAGE"
                                        );
                                      }}
                                      className={`seatPurpose flex items-center border rounded-lg p-1 ${
                                        item.occupant_type === "LAGGAGE"
                                          ? " border-oya-red-100"
                                          : "border-oya-gray-100"
                                      }`}
                                    >
                                      <Radio
                                        checked={
                                          item.occupant_type === "LAGGAGE"
                                            ? true
                                            : false
                                        }
                                      />
                                      <div className="text-oya-gray text-[14px]">
                                        Luggage
                                      </div>
                                    </button>
                                  </div>

                                  <div className="my-4 flex justify-between items-center">
                                    <div className="font-semibold text-[14px] text-oya-gray">
                                      Share seat with a minor
                                    </div>
                                    <div className="shareWithMinorContainer">
                                      <Switch
                                        checked={
                                          item.shareWithMinor === true
                                            ? true
                                            : false
                                        }
                                        data-testid="shareWithMinor"
                                        onChange={(checked) => {
                                          const newDetail = seats.map(
                                            (newItem) =>
                                              newItem.index === item?.index
                                                ? {
                                                    ...newItem,
                                                    shareWithMinor: checked,
                                                  }
                                                : newItem
                                          );

                                          setSeats(newDetail);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <div className="text-[14px] font-medium">
                                      Where will you join the trip?
                                    </div>
                                    <div className="flex flex-col gap-3  mt-2">
                                      <button
                                        onClick={() => {
                                          handlePickupChange(
                                            index,
                                            undefined,
                                            "BUS-TERMINAL"
                                          );
                                        }}
                                        className={`seatPurpose w-full flex border rounded-lg py-2 px-2 ${
                                          item.pickup_name === "BUS-TERMINAL"
                                            ? " border-oya-red-100"
                                            : "border-oya-gray-100"
                                        }`}
                                      >
                                        <Radio
                                          checked={
                                            item.pickup_name === "BUS-TERMINAL"
                                              ? true
                                              : false
                                          }
                                        />
                                        <div>
                                          <div className="text-oya-gray text-[14px]">
                                            Bus terminal
                                          </div>
                                          <div className="text-oya-gray-200 text-[10px]">
                                            Join at the station
                                          </div>
                                        </div>
                                      </button>
                                      <button
                                        onClick={() => {
                                          setShowPickupModal(true);
                                          setSeatIndex(item?.index);
                                        }}
                                        className={`seatPurpose w-full flex  border rounded-lg py-2 px-2 ${
                                          item.pickup_name === "MIDROUTE"
                                            ? " border-oya-red-100"
                                            : "border-oya-gray-100"
                                        }`}
                                      >
                                        <Radio
                                          checked={
                                            item.pickup_name === "MIDROUTE"
                                              ? true
                                              : false
                                          }
                                        />
                                        <div className="">
                                          <div className="text-oya-gray text-[14px] ">
                                            Mid-route
                                          </div>
                                          <div className="text-oya-gray-200 text-[10px] ">
                                            {selectedMidRoute
                                              ? selectedMidRoute.label
                                              : "Join at a bus stop"}
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  </div>

                                  <div className="mt-2">
                                    {item.occupant_type === "ADULT" && (
                                      <>
                                        <Form.Item
                                          colon={false}
                                          label="First name"
                                          name={`passenger_first_name_${index}`}
                                          initialValue={
                                            item.index === 0
                                              ? item?.passenger?.first_name
                                              : ""
                                          }
                                          rules={[
                                            {
                                              required: true,
                                              message: "This field is required",
                                            },
                                          ]}
                                          data-testid="passengerFirstName"
                                        >
                                          <Input
                                            size="large"
                                            placeholder="Doe"
                                            onChange={(e) => {
                                              const newDetails = seats.map(
                                                (newItem) =>
                                                  newItem.index === index
                                                    ? {
                                                        ...newItem,
                                                        passenger: {
                                                          ...newItem.passenger,
                                                          first_name:
                                                            e.target.value,
                                                        },
                                                      }
                                                    : newItem
                                              );
                                              setSeats(newDetails);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          colon={false}
                                          label="Last name"
                                          initialValue={
                                            item.index === 0
                                              ? item?.passenger?.last_name
                                              : ""
                                          }
                                          rules={[
                                            {
                                              required: true,
                                              message: "This field is required",
                                            },
                                          ]}
                                          name={`passenger_last_name_${index}`}
                                          data-testid="passengerLastName"
                                        >
                                          <Input
                                            size="large"
                                            placeholder="Kweku"
                                            onChange={(e) => {
                                              const newDetails = seats.map(
                                                (newItem) =>
                                                  newItem.index === index
                                                    ? {
                                                        ...newItem,
                                                        passenger: {
                                                          ...newItem.passenger,
                                                          last_name:
                                                            e.target.value,
                                                        },
                                                      }
                                                    : newItem
                                              );
                                              setSeats(newDetails);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          colon={false}
                                          label="Phone number"
                                          name={`passenger_phone_${index}`}
                                          initialValue={
                                            item?.index === 0
                                              ? item?.passenger?.phone
                                              : ""
                                          }
                                          rules={[
                                            {
                                              required: true,
                                              message: "This field is required",
                                            },
                                          ]}
                                          data-testid="passengerPhone"
                                        >
                                          <PhoneInput
                                            onChange={(e) => {
                                              let formatted = e?.replace(
                                                /\s+/g,
                                                ""
                                              );
                                              // setPhone(formatted);
                                              const newDetails = seats.map(
                                                (newItem) =>
                                                  newItem.index === index
                                                    ? {
                                                        ...newItem,
                                                        passenger: {
                                                          ...newItem.passenger,
                                                          phone: formatted,
                                                        },
                                                      }
                                                    : newItem
                                              );
                                              setSeats(newDetails);
                                            }}
                                            placeholder="0244123456"
                                            className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                                            defaultCountry="GH"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          colon={false}
                                          label="Primary Emergency Contact"
                                          name={`passenger_ice_${index}`}
                                          initialValue={
                                            item?.index === 0
                                              ? item?.passenger?.ice_phone
                                              : ""
                                          }
                                          rules={[
                                            {
                                              required: true,
                                              message: "This field is required",
                                            },
                                          ]}
                                          data-testid="passengerIcePhone"
                                        >
                                          <PhoneInput
                                            onChange={(e) => {
                                              let formatted = e?.replace(
                                                /\s+/g,
                                                ""
                                              );
                                              const newDetails = seats.map(
                                                (newItem) =>
                                                  newItem.index === index
                                                    ? {
                                                        ...newItem,
                                                        passenger: {
                                                          ...newItem.passenger,
                                                          ice_phone: formatted,
                                                        },
                                                      }
                                                    : newItem
                                              );
                                              setSeats(newDetails);
                                            }}
                                            placeholder="0244123456"
                                            className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                                            defaultCountry="GH"
                                          />
                                        </Form.Item>
                                      </>
                                    )}

                                    {item.occupant_type === "MINOR" && (
                                      <>
                                        <Form.Item
                                          colon={false}
                                          label="Minor first name"
                                          name={`passenger_first_name_${index}`}
                                          data-testid="minorFirstName"
                                        >
                                          <Input
                                            size="large"
                                            placeholder="Doe"
                                            onChange={(e) => {
                                              const newDetails = seats.map(
                                                (newItem) =>
                                                  newItem.index === index
                                                    ? {
                                                        ...newItem,
                                                        passenger: {
                                                          ...newItem.passenger,
                                                          first_name:
                                                            e.target.value,
                                                        },
                                                      }
                                                    : newItem
                                              );
                                              setSeats(newDetails);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          colon={false}
                                          label="Minor last name"
                                          name={`passenger_last_name_${index}`}
                                          data-testid="minorLastName"
                                        >
                                          <Input
                                            size="large"
                                            placeholder="Kweku"
                                            onChange={(e) => {
                                              const newDetails = seats.map(
                                                (newItem) =>
                                                  newItem.index === index
                                                    ? {
                                                        ...newItem,
                                                        passenger: {
                                                          ...newItem.passenger,
                                                          last_name:
                                                            e.target.value,
                                                        },
                                                      }
                                                    : newItem
                                              );
                                              setSeats(newDetails);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          colon={false}
                                          label="Emergency Contact"
                                          name={`passenger_ice_${index}`}
                                          initialValue={
                                            item.index === index
                                              ? item?.passenger?.ice_phone
                                              : ""
                                          }
                                          data-testid="passengerIcePhone"
                                        >
                                          <PhoneInput
                                            onChange={(e) => {
                                              let formatted = e?.replace(
                                                /\s+/g,
                                                ""
                                              );
                                              const newDetails = seats.map(
                                                (newItem) =>
                                                  newItem.index === index
                                                    ? {
                                                        ...newItem,
                                                        passenger: {
                                                          ...newItem.passenger,
                                                          ice_phone: formatted,
                                                        },
                                                      }
                                                    : newItem
                                              );
                                              setSeats(newDetails);
                                            }}
                                            placeholder="0244123456"
                                            className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                                            defaultCountry="GH"
                                          />
                                        </Form.Item>
                                      </>
                                    )}

                                    {item.occupant_type === "LAGGAGE" && (
                                      <>
                                        <Form.Item
                                          colon={false}
                                          label="Luggage description"
                                          name={`description_${index}`}
                                          data-testid="passengerLuggageDescription"
                                        >
                                          <Input.TextArea
                                            size="large"
                                            placeholder="A brief description of your luggage"
                                            onChange={(e) => {
                                              const newDetails = seats.map(
                                                (newItem) =>
                                                  newItem.index === index
                                                    ? {
                                                        ...newItem,
                                                        luggage_description:
                                                          e.target.value,
                                                      }
                                                    : newItem
                                              );
                                              setSeats(newDetails);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          colon={false}
                                          label="Phone number"
                                          name={`passenger_phone_${index}`}
                                          initialValue={
                                            item.index === index
                                              ? item?.passenger?.phone
                                              : ""
                                          }
                                          data-testid="passengerPhone"
                                        >
                                          <PhoneInput
                                            onChange={(e) => {
                                              let formatted = e?.replace(
                                                /\s+/g,
                                                ""
                                              );
                                              // setPhone(formatted);
                                              const newDetails = seats.map(
                                                (newItem) =>
                                                  newItem.index === index
                                                    ? {
                                                        ...newItem,
                                                        passenger: {
                                                          ...newItem.passenger,
                                                          phone: formatted,
                                                        },
                                                      }
                                                    : newItem
                                              );
                                              setSeats(newDetails);
                                            }}
                                            placeholder="0244123456"
                                            className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                                            defaultCountry="GH"
                                          />
                                        </Form.Item>
                                      </>
                                    )}
                                  </div>
                                  <div className="w-full justify-center items-center">
                                    <button
                                      onClick={() => {
                                        setSeatIndex(item?.index);
                                        setShowRemoveSeat(true);
                                      }}
                                      className="border border-oya-red-100 w-full rounded-lg flex justify-center items-center py-2"
                                    >
                                      <div className="text-[16px] text-oya-red-100 font-medium">
                                        Remove seat
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </AccordionBody>
                            </Accordion>
                            <RemoveSeatModal
                              visible={showRemoveSeat}
                              handleClose={() => setShowRemoveSeat(false)}
                              handleRemove={() => handleRemoveSeat(item.index)}
                            />
                            <MidrouteModal
                              isOpen={showPickupModal}
                              handleClose={() => setShowPickupModal(false)}
                              handleSelected={(value) => {
                                // console.log({
                                //   item: item.seat_id,
                                //   value,
                                //   bus: "MIDROUTE",
                                // });
                                handleSelectedMidroute(
                                  seatIndex,
                                  value,
                                  "MIDROUTE"
                                );
                              }}
                              selected={
                                item.index === index && item.selected_pickup
                              }
                              routeId={schedule?.route?.id}
                              isMidroute={schedule?.mid_route}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Form>
              )}
            </div>
            <div className="fixed w-full bottom-0">
              <div className="shadow-[0_30px_50px_15px_rgba(0,0,0,0.3)] py-5 px-7 rounded-t-[12px] bg-white">
                <div className="flex w-full justify-between items-center">
                  {loading ? (
                    <div className="animate-pulse w-[100px] bg-oya-gray-100 rounded-md h-[30px]" />
                  ) : (
                    <div className="block">
                      <div className="font-semibold text-oya-gray text-[14px]">
                        {schedule?.price?.currency}{" "}
                        {seats.length > 0
                          ? Intl.NumberFormat("en-EN").format(
                              Number(schedule?.price?.amount) * seats.length
                            )
                          : Intl.NumberFormat("en-EN").format(
                              Number(schedule?.price?.amount)
                            )}
                      </div>
                      {seats.length >= 0 && (
                        <div className="text-oya-gray-200">
                          {seats.length} seats booked
                        </div>
                      )}
                    </div>
                  )}

                  {!loading && (
                    <>
                      {seats && seats?.length === 0 ? (
                        <button
                          onClick={() => handleSubmit()}
                          disabled={submitting}
                          className="bg-gray-200 text-black rounded-lg w-[150px] h-[42px] flex justify-center items-center"
                        >
                          <div className="text-[16px]">Book</div>
                        </button>
                      ) : (
                        <button
                          onClick={() => handleSubmit()}
                          disabled={submitting}
                          className="bg-oya-red-100 rounded-lg w-[150px] h-[42px] flex justify-center items-center text-white"
                        >
                          {submitting ? (
                            <Spinner />
                          ) : (
                            <div className="text-[16px]">Book</div>
                          )}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="min-h-screen mt-[6rem] mx-[15px]">
            <div>Schedule not found</div>
          </div>
        )}
        <SeatModal
          isOpen={showSeatModal}
          handleClose={() => setShowSeatModal(false)}
          scheduleId={params?.id}
          handleSelected={(seatSelected) => {
            handleSelectedSeat(seatSelected);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default TripDetails;
